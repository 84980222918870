








































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { ErrorManager } from '@/models/error';
import { AuthLoginFieldError } from '@/models';

export default Vue.extend({
  name: 'Login',

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'SignIn'
  },

  data() {
    return {
      showPassword: false,
      password: 'Password',
      checkbox: true,
      email: '',
      ePassword: '',
      errorMessage: '',
      loading: false,
      fieldError: new AuthLoginFieldError(),

      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],

      passwordRules: [(v: string) => !!v || 'Password is required']
    };
  },

  computed: {
    ...mapGetters(['loggedInUser', 'error']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    submitForm() {
      this.clearAllErrors();

      this.loading = true;

      this.login({ email: this.email, password: this.ePassword })
        .then(() => this.$router.push('/'))
        .catch((error) => {
          if (error.response) {
            // client received an error response that falls out of range 2xx
            if (AuthLoginFieldError.isLoginFieldError(error.response.data)) {
              this.fieldError = new AuthLoginFieldError(error.response.data);
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
